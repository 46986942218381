/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/jquery-migrate@3.3.2/dist/jquery-migrate.min.js
 * - /npm/underscore@1.13.6/underscore-min.min.js
 * - /npm/backbone@1.4.1/backbone-min.min.js
 * - /npm/svgxuse@1.2.6/svgxuse.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
